var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "추가정보" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-danger-area", {
                  ref: "dangerArea",
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "위험물저장소",
                    name: "chmDangerMstId",
                  },
                  on: { detailInfo: _vm.detailInfo },
                  model: {
                    value: _vm.check.chmDangerMstId,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerMstId", $$v)
                    },
                    expression: "check.chmDangerMstId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-field", {
                  attrs: {
                    editable: _vm.editable,
                    data: _vm.check,
                    disabled: true,
                    label: "안전관리자",
                    type: "user",
                    name: "chmDangerManageUserId",
                  },
                  model: {
                    value: _vm.check.chmDangerManageUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerManageUserId", $$v)
                    },
                    expression: "check.chmDangerManageUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-field", {
                  attrs: {
                    editable: _vm.editable,
                    data: _vm.check,
                    disabled: true,
                    label: "담당자",
                    type: "user",
                    name: "chmDangerUserId",
                  },
                  model: {
                    value: _vm.check.chmDangerUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerUserId", $$v)
                    },
                    expression: "check.chmDangerUserId",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "tableType",
        attrs: {
          title: "저장품목",
          columns: _vm.gridType.columns,
          gridHeight: _vm.itemHeight,
          data: _vm.check.checkitems,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          noDataLabel: "저장품목을 추가하세요.",
          hideBottom: true,
          rowKey: "chmDangerRegularCheckItemId",
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "calcuMethod"
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("toThousandFilter")(
                              props.row["calcuOriginal"] *
                                props.row["calcuMultiple"]
                            )
                          ) +
                          " "
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }